import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      light: "#5773ff",
      main: "#5773ff",
      dark: "#5773ff",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    action: {
      light: "#333",
      main: "#333",
      dark: "#333",
      contrastText: "#333",
    },
  },

  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
  },
});
export default theme;
